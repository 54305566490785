import { runtimeConfig } from "@src/runtime-config";

/**
 * @returns a HB Dashboard URL that keeps the current XG Dashboard path
 */
export function getHBDashboardUrl(): URL {
  const path = checkAndUpdatePath(window.location.pathname);
  const newUrl = new URL(path, runtimeConfig.urls.hbDashboardUrl);

  // Append the `force=true` and `useHB=true` query params to prevent that the HB DB re-directs back to XG DB
  newUrl.searchParams.append("force", "true");
  newUrl.searchParams.append("useHB", "true");

  return newUrl;
}

/** Regex that matches the route paths in the project details tabs */
const PROJECT_DETAILS_PATH_MATCHER = /\/([^/]+)\/projects\/([^/]+)\/([^/]+)/;

/** Regex that matches the project details tabs that exist in the HDB */
const HB_PROJECT_DETAILS_TAB_MATCHER = /(details|team|integrations|snapshots)/;

/** Regex that matches the route paths in the analytics tabs */
const ANALYTICS_PATH_MATCHER = /\/([^/]+)\/analytics\/([^/]+)/;

/**
 * Function that checks for specific SDB route paths that don't exist in HDB. If the passed path does not exist
 * in HDB then it replaces the path with one that exist in HDB.
 *
 * @returns The updated path if the path doesn't exist in HDB or the original path if it exists.
 */
export function checkAndUpdatePath(path: string): string {
  const isProjectDetailsPath = PROJECT_DETAILS_PATH_MATCHER.test(path);
  if (isProjectDetailsPath) {
    const updatedPath = path.replace(
      PROJECT_DETAILS_PATH_MATCHER,
      (match, companyId, projectId, projectTab): string => {
        // Check if the tab is a valid HDB tab
        const isValidTab = HB_PROJECT_DETAILS_TAB_MATCHER.test(projectTab);

        // If the tab is valid return the original path, otherwise return the project details path
        return isValidTab
          ? path
          : `/${companyId}/projects/${projectId}/details`;
      }
    );

    return updatedPath;
  }

  const isAnalyticsPath = ANALYTICS_PATH_MATCHER.test(path);
  if (isAnalyticsPath) {
    const updatedPath = path.replace(
      ANALYTICS_PATH_MATCHER,
      (match, companyId): string => {
        return `/${companyId}/analytics`;
      }
    );

    return updatedPath;
  }

  return path;
}
